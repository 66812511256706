.fullscreen-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ff00ff;
    display: flex;
    justify-content: center;
    align-items: center;
}